<template>
    <section>
        <div class="row">
                <div class="col-md-12">
                    <h1 class="text-center">Nous avons rencontré une erreur! </h1>
                    <picture>
                        <source class="card-img-top"  srcset="@/assets/images/import/payments_re.webp" type="image/webp">
                        <source class="card-img-top"  srcset="@/assets/images/import/payments_re.png" type="image/png">
                        <img src="@/assets/images/import/payments_re.png" alt="">
                    </picture>
                </div>
                <div class="col-md-12 d-flex justify-content-around">
                    <button class=" btn-outline-primary btn" @click="home()">Revenir sur la page d'accueil</button>
                    <button class=" btn-primary btn">Réessayer le paiement</button>
                </div>
            </div>
    </section>
</template>
<script>
export default {
    name: "payment-failed",
    methods: {
        home() {
            this.$router.push ({
                name:"home"
            })
        }
    }
}
</script>